<template>
  <v-container
    v-if="hasPermission(employeePermissions.SHOW_STATS)"
    fluid
    tag="section"
  >
    <v-row>
      <v-col v-if="loggedUserIsAdmin && !hideOnboarding">
        <dashboard-stepper @hideDashboardOnboarding="hideDashboardOnboarding" />
      </v-col>
      <v-col
        v-if="loggedUserIsAdmin && hideOnboarding"
        class="d-flex justify-end"
      >
        <v-container fluid class="px-0">
          <v-row>
            <v-col class="d-flex justify-end">
              <v-btn
                text
                color="primary"
                x-small
                @click="showDashboardOnboarding"
              >
                {{ $trans("enable_configuration") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <dashboard-stats />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DashboardStats from "@/views/dashboard/pages/Dashboard/components/DashboardStats";
import DashboardStepper from "@/views/dashboard/pages/Dashboard/components/DashboardStepper/DashboardStepper";
import roleActions from "@/calendesk/mixins/roleActions";
import { pushEvent } from "@/lib/calendesk-js-library/tools/helpers";
import { mapActions } from "vuex";

export default {
  name: "DashboardDashboard",
  components: {
    DashboardStats,
    DashboardStepper,
  },
  mixins: [roleActions],
  data() {
    return {
      hideOnboarding: !!this.$adminConfig("app_dashboard_hide_onboarding"),
    };
  },
  created() {
    if (this.$route.query.plan) {
      this.$root.$emit("openPlanView", false);
    }
  },
  methods: {
    ...mapActions({
      updateConfiguration: "setup/updateConfiguration",
    }),
    showDashboardOnboarding() {
      pushEvent("showDashboardOnboarding");
      this.hideOnboarding = false;
      this.updateConfiguration({
        app_dashboard_hide_onboarding: 0,
      });
    },
    hideDashboardOnboarding() {
      pushEvent("hideDashboardOnboarding");
      this.hideOnboarding = true;
      this.updateConfiguration({
        app_dashboard_hide_onboarding: 1,
      });
    },
  },
};
</script>
