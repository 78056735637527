<template>
  <v-container fluid class="px-0">
    <v-row>
      <v-col cols="6" class="d-flex justify-start mb-2 font-weight-medium">
        {{ $trans("quick_configuration") }}
      </v-col>
      <v-col cols="6" class="d-flex justify-end">
        <v-btn
          text
          color="primary"
          x-small
          @click="$emit('hideDashboardOnboarding', true)"
        >
          {{ $trans("disable_configuration") }}
        </v-btn>
      </v-col>
    </v-row>
    <v-stepper v-model="stepperIndex" class="snow_gray" vertical>
      <v-stepper-step
        :step="1"
        class="pointer"
        :complete="stepperIndex > 1"
        @click="goToStepperStep(1)"
      >
        <span v-if="getDomain">{{ $trans("stepper_1_title") }}</span>
        <span v-else>{{ $trans("stepper_1_title_a") }}</span>
      </v-stepper-step>
      <v-stepper-content :step="1">
        <v-card color="white" outlined class="text-body-2 pa-2 mb-4">
          <div class="mb-2">
            <span v-if="getDomain">{{ $trans("stepper_1_description") }}</span>
            <span v-else>{{ $trans("stepper_1_description_a") }}</span>
          </div>
          <div v-if="getDomain" class="font-weight-medium mt-4">
            {{ $trans("calendar_url_link") }}:
            <a @click="openCalendarUrl(getDomain)">{{ getDomain }} </a>
          </div>
          <div class="mt-8">
            <v-btn
              color="blue"
              :block="$vuetify.breakpoint.width < 600"
              outlined
              small
              :to="{ name: 'tools', params: { tab: 'websites' } }"
            >
              <span v-if="getDomain">{{ $trans("edit_website_button") }}</span>
              <span v-else>{{ $trans("drafts_create_website_button") }}</span>
            </v-btn>
            <v-btn
              v-if="getDomain"
              color="blue"
              target="_blank"
              class="ml-sm-2 mt-2 mt-sm-0"
              :block="$vuetify.breakpoint.width < 600"
              outlined
              small
              @click="openCalendarUrl(getDomain)"
            >
              {{ $trans("open_website_button") }}
            </v-btn>
          </div>
        </v-card>
        <div>
          <v-btn color="primary" small @click="goToStepperStep(2)">
            {{ $trans("next") }}
          </v-btn>
        </div>
      </v-stepper-content>

      <v-stepper-step
        :step="2"
        class="pointer"
        :complete="stepperIndex > 2"
        @click="goToStepperStep(2)"
      >
        {{ $trans("stepper_2_title") }}
      </v-stepper-step>
      <v-stepper-content :step="2">
        <v-card color="white" outlined class="text-body-2 pa-2 mb-4">
          <div class="mb-2">
            {{ $trans("stepper_2_description") }}
          </div>
          <div class="mt-8">
            <v-btn
              color="blue"
              small
              :block="$vuetify.breakpoint.width < 600"
              outlined
              :to="{ name: 'employees' }"
            >
              {{ $trans("view_employees_account_button") }}
            </v-btn>
          </div>
        </v-card>
        <div class="mt-4">
          <v-btn color="primary" small @click="goToStepperStep(3)">
            {{ $trans("next") }}
          </v-btn>
        </div>
      </v-stepper-content>

      <v-stepper-step
        :step="3"
        class="pointer"
        :complete="stepperIndex > 3"
        @click="goToStepperStep(3)"
      >
        {{ $trans("stepper_3_title") }}
      </v-stepper-step>
      <v-stepper-content :step="3">
        <v-card color="white" outlined class="text-body-2 pa-2 mb-4">
          <div class="mb-2">
            {{ $trans("stepper_3_description") }}
          </div>
          <div class="mt-8">
            <v-btn
              color="blue"
              :to="{ name: 'offer', params: { tab: 'services' } }"
              :block="$vuetify.breakpoint.width < 600"
              outlined
              small
            >
              {{ $trans("view_services_button") }}
            </v-btn>
          </div>
        </v-card>
        <div class="mt-4">
          <v-btn color="primary" small @click="goToStepperStep(4)">
            {{ $trans("next") }}
          </v-btn>
        </div>
      </v-stepper-content>

      <v-stepper-step
        :step="4"
        class="pointer"
        :complete="stepperIndex > 4"
        @click="goToStepperStep(4)"
      >
        {{ $trans("stepper_4_title") }}
      </v-stepper-step>
      <v-stepper-content :step="4">
        <v-card color="white" outlined class="text-body-2 pa-2 mb-4">
          <div class="mb-2">
            {{ $trans("stepper_4_description") }}
          </div>
          <div class="mt-8">
            <v-btn
              color="blue"
              :to="{ name: 'integrations', params: { integration: 'stripe' } }"
              :block="$vuetify.breakpoint.width < 600"
              outlined
              small
            >
              {{ $trans("configure_payments_button") }}
            </v-btn>
          </div>
        </v-card>
        <div class="mt-4">
          <v-btn color="primary" small @click="goToStepperStep(5)">
            {{ $trans("next") }}
          </v-btn>
        </div>
      </v-stepper-content>

      <v-stepper-step
        :step="5"
        class="pointer"
        :complete="stepperIndex > 5"
        @click="goToStepperStep(5)"
      >
        {{ $trans("stepper_5_title") }}
      </v-stepper-step>
      <v-stepper-content :step="5">
        <v-card color="white" outlined class="text-body-2 pa-2 mb-4">
          <div class="mb-2">
            {{ $trans("stepper_5_description") }}
          </div>
          <div class="mt-8">
            <v-btn
              color="blue"
              outlined
              target="_blank"
              small
              :block="$vuetify.breakpoint.width < 600"
              @click="handleMeetUsClick"
            >
              {{ $trans("meet_us") }}
            </v-btn>
            <v-btn
              color="blue"
              class="ml-sm-2 mt-2 mt-sm-0"
              :block="$vuetify.breakpoint.width < 600"
              outlined
              small
              @click="openHelpCenter"
            >
              {{ $trans("help_center") }}
            </v-btn>
          </div>
        </v-card>
        <div class="mt-4">
          <v-btn color="primary" small @click="goToStepperStep(6)">
            {{ $trans("finish") }}
          </v-btn>
        </div>
      </v-stepper-content>
    </v-stepper>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import {
  getItem,
  setItem,
} from "@/lib/calendesk-js-library/tools/localStorageManager";
import {
  handleMeetUsClick,
  pushEvent,
} from "@/lib/calendesk-js-library/tools/helpers";
import sharedActions from "@/lib/calendesk-js-library/mixins/sharedActions";

export default {
  mixins: [sharedActions],
  data() {
    return {
      stepperIndex: null,
    };
  },
  computed: {
    ...mapGetters({
      webDrafts: "webDraft/drafts",
    }),
    getDomain() {
      if (
        this.webDrafts &&
        this.webDrafts.length > 0 &&
        this.webDrafts[0].domain
      ) {
        return `https://${this.webDrafts[0].domain.domain}`;
      }

      return null;
    },
  },
  watch: {
    stepperIndex(val) {
      setItem("stepperIndex", val, false, true);
    },
  },
  created() {
    const storedIndex = getItem("stepperIndex", false, true);

    if (storedIndex) {
      this.stepperIndex = parseInt(storedIndex);
    } else {
      this.stepperIndex = 1;
    }
  },
  methods: {
    openHelpCenter() {
      pushEvent("openHelpCenter");
      const locale = localStorage.getItem("locale").toLowerCase();

      if (locale === "pl") {
        window.open(`https://calendesk.com/pl/help/`, "_blank");
      } else {
        window.open(`https://calendesk.com/help/`, "_blank");
      }
    },
    goToStepperStep(step) {
      pushEvent("updateDashboardOnboardingStep", {
        step: step,
      });

      this.stepperIndex = step;
    },
    handleMeetUsClick,
    openCalendarUrl(url) {
      pushEvent("openOwnCalendarUrl");

      window.open(url, "_blank");
    },
  },
};
</script>
