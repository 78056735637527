<template>
  <v-row>
    <template v-for="(element, key) in statsData">
      <v-col :key="key" cols="12" sm="12" md="6">
        <v-card class="mt-4 pa-2 pb-0 mx-auto" outlined rounded="lg">
          <v-card-text class="fill-height">
            <g-chart
              v-if="element.data.length > 0"
              :type="chartType"
              :data="element.data"
              :options="{
                legend: { position: 'none' },
              }"
            />
            <v-sheet
              v-else
              class="justify-center d-flex align-center subheading font-weight-light grey--text"
              style="height: 200px"
            >
              {{ $trans("no_data") }}
            </v-sheet>
          </v-card-text>
          <v-card-text>
            <div class="text-h6 font-weight-light mb-2">
              {{ element.title }}
            </div>
            <div class="subheading font-weight-light grey--text">
              {{ element.subtitle }}
            </div>
          </v-card-text>
          <template v-if="element.redirect">
            <v-divider />
            <v-card-actions class="justify-center">
              <v-btn
                block
                text
                color="blue"
                @click="$router.push(element.redirect)"
              >
                {{ element.redirect_title }}
              </v-btn>
            </v-card-actions>
          </template>
        </v-card>
      </v-col>
    </template>
  </v-row>
</template>

<script>
import { GChart } from "vue-google-charts";
import sharedActions from "@/lib/calendesk-js-library/mixins/sharedActions";

export default {
  name: "DashboardCharts",
  components: { GChart },
  mixins: [sharedActions],
  props: {
    stats: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      chartType: "ColumnChart",
      statsLastUpdated: null,
      statsData: {
        monthly_bookings_amount: {
          title: this.$trans("number_of_bookings_by_amount_title"),
          subtitle: this.$trans("monthly_divided"),
          color: "primary",
          data: [],
          redirect: { name: "sales", params: { tab: "events" } },
          redirect_title: this.$trans("bookings"),
        },
        monthly_user_subscriptions_amount: {
          title: this.$trans("number_of_user_subscriptions_by_amount_title"),
          subtitle: this.$trans("monthly_divided"),
          color: "blue",
          data: [],
          redirect: { name: "sales", params: { tab: "subscriptions" } },
          redirect_title: this.$trans("subscriptions"),
        },
        monthly_simple_store_product_transactions_amount: {
          title: this.$trans("number_of_simple_store_product_by_amount_title"),
          subtitle: this.$trans("monthly_divided"),
          color: "green",
          data: [],
          redirect: { name: "sales", params: { tab: "products" } },
          redirect_title: this.$trans("simple_store_product_transaction_list"),
        },
        monthly_users: {
          title: this.$trans("number_of_new_users"),
          subtitle: this.$trans("monthly_divided"),
          color: "primary",
          data: [],
          redirect: { name: "users" },
          redirect_title: this.$trans("users"),
        },
        monthly_bookings: {
          title: this.$trans("number_of_bookings"),
          subtitle: this.$trans("monthly_divided"),
          color: "blue",
          data: [],
          redirect: { name: "sales", params: { tab: "events" } },
          redirect_title: this.$trans("bookings"),
        },
        monthly_user_subscriptions: {
          title: this.$trans("number_of_user_subscriptions"),
          subtitle: this.$trans("monthly_divided"),
          color: "green",
          data: [],
          redirect: { name: "sales", params: { tab: "subscriptions" } },
          redirect_title: this.$trans("subscriptions"),
        },
        monthly_simple_store_product_transactions: {
          title: this.$trans("number_of_simple_store_products"),
          subtitle: this.$trans("monthly_divided"),
          color: "green",
          data: [],
          redirect: { name: "sales", params: { tab: "products" } },
          redirect_title: this.$trans("simple_store_product_transaction_list"),
        },
        monthly_newsletter: {
          title: this.$trans("number_of_newsletter_members"),
          subtitle: this.$trans("monthly_divided"),
          color: "green",
          data: [],
          redirect: { name: "notifications", params: { tab: "newsletter" } },
          redirect_title: this.$trans("newsletter"),
        },
      },
    };
  },
  mounted() {
    this.fillStatsData();
  },
  methods: {
    fillStatsData() {
      const list = [
        "monthly_bookings_amount",
        "monthly_user_subscriptions_amount",
        "monthly_simple_store_product_transactions_amount",
        "monthly_users",
        "monthly_bookings",
        "monthly_user_subscriptions",
        "monthly_simple_store_product_transactions",
        "monthly_newsletter",
      ];
      list.map((key) => {
        const stats = this.stats[key];
        const data = [[this.$trans("month"), this.$trans("value")]];

        if (stats.length > 0) {
          stats.forEach((stat) => {
            data.push([this.$trans(`short_month_${stat.month}`), stat.count]);
          });

          this.$set(this.statsData[key], "data", data);
        }
      });
    },
  },
};
</script>
