<template>
  <v-container v-if="hasStats" fluid class="px-0 mx-0">
    <v-row class="mb-2">
      <v-col v-if="notificationUsage" cols="12" sm="6" md="3">
        <dashboard-stats-card
          :warning="!onTrial && notificationUsage.email <= 50"
          :number="+notificationUsage.email"
          :title="$trans('email_notifications')"
          icon="$email"
          avatar-background-color="blue-light"
          avatar-icon-color="blue"
          :button-title="$trans('buy_more')"
          :button-handler="buyNotificationButtonClicked"
          :button-data="{ slug: 'email-notifications' }"
        />
      </v-col>
      <v-col v-if="notificationUsage" cols="12" sm="6" md="3">
        <dashboard-stats-card
          :warning="!onTrial && notificationUsage.sms <= 10"
          :number="+notificationUsage.sms"
          :title="$trans('sms_notifications')"
          icon="$mobile"
          avatar-background-color="green-light"
          avatar-icon-color="green"
          :button-title="$trans('buy_more')"
          :button-handler="buyNotificationButtonClicked"
          :button-data="{ slug: 'sms-notifications' }"
        />
      </v-col>
      <v-col v-if="getDomain" cols="12" md="6">
        <v-card outlined class="stats__v-card">
          <v-list-item>
            <v-list-item-title class="light--text">
              {{ $trans("websites") }} /
              {{ $trans("integrations_category_calendars") }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-avatar
              tile
              size="56"
              min-height="56"
              color="pink"
              class="stats__v-card--avatar"
            >
              <v-icon size="24" color="primary"> $application </v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <div>
                <div
                  v-for="webDraft in webDrafts.slice(0, 2)"
                  :key="webDraft.domain.domain"
                  class="mb-1"
                >
                  <a
                    @click.stop="
                      $helpers.openUrl('https://' + webDraft.domain.domain)
                    "
                    >{{ `https://${webDraft.domain.domain}` }}
                  </a>
                </div>
              </div>
            </v-list-item-content>
          </v-list-item>
          <v-divider class="mx-2" />
          <v-card-actions>
            <v-btn text block color="blue" @click="goToWebsites">
              {{ $trans("go_to_tab") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="mb-2">
      <v-col cols="12" sm="6" md="3">
        <dashboard-stats-card
          redirect="users"
          :number="+stats.users"
          :title="$trans('users')"
          icon="$account-multiple"
          avatar-background-color="pink"
          avatar-icon-color="primary"
          :button-title="$trans('go_to_tab')"
        />
      </v-col>

      <v-col cols="12" sm="6" md="3">
        <dashboard-stats-card
          redirect="sales"
          :redirect-params="{ tab: 'events' }"
          :redirect-query="{ period: 'today' }"
          :number="+stats.bookings_today"
          :title="$trans('bookings_today')"
          icon="$calendar"
          avatar-background-color="blue-light"
          avatar-icon-color="blue"
          :button-title="$trans('go_to_tab')"
        />
      </v-col>

      <v-col cols="12" sm="6" md="3">
        <dashboard-stats-card
          redirect="sales"
          :redirect-params="{ tab: 'events' }"
          :redirect-query="{ period: 'week' }"
          :number="+stats.bookings_this_week"
          :title="$trans('bookings_this_week')"
          icon="$calendar-range"
          avatar-background-color="blue-light"
          avatar-icon-color="blue"
          :button-title="$trans('go_to_tab')"
        />
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <dashboard-stats-card
          redirect="notifications"
          :redirect-params="{ tab: 'newsletter' }"
          :number="+stats.newsletter"
          :title="$trans('number_of_newsletter_members')"
          icon="$email"
          avatar-background-color="green-light"
          avatar-icon-color="green"
          :button-title="$trans('go_to_tab')"
        />
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <dashboard-stats-card
          redirect="sales"
          :redirect-params="{ tab: 'subscriptions' }"
          :number="+stats.active_user_subscriptions"
          :title="$trans('number_of_active_user_subscriptions')"
          icon="$subscriptions"
          avatar-background-color="yellow-light"
          avatar-icon-color="orange"
          :button-title="$trans('go_to_tab')"
        />
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <dashboard-stats-card
          redirect="sales"
          :redirect-params="{ tab: 'products' }"
          :number="+stats.simple_store_product_transactions"
          :title="$trans('number_of_simple_store_product_transactions')"
          icon="$cart"
          avatar-background-color="pink"
          avatar-icon-color="primary"
          :button-title="$trans('go_to_tab')"
        />
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <dashboard-stats-card
          redirect="sales"
          :redirect-params="{ tab: 'products' }"
          :redirect-query="{ period: 'today' }"
          :number="+stats.simple_store_product_transactions_today"
          :title="$trans('number_of_simple_store_product_transactions_today')"
          icon="$cart"
          avatar-background-color="pink"
          avatar-icon-color="primary"
          :button-title="$trans('go_to_tab')"
        />
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <dashboard-stats-card
          redirect="sales"
          :redirect-params="{ tab: 'products' }"
          :redirect-query="{ period: 'week' }"
          :number="+stats.simple_store_product_transactions_this_week"
          :title="
            $trans('number_of_simple_store_product_transactions_this_week')
          "
          icon="$cart"
          avatar-background-color="pink"
          avatar-icon-color="primary"
          :button-title="$trans('go_to_tab')"
        />
      </v-col>
    </v-row>

    <dashboard-charts :stats="stats" />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import DashboardCharts from "./DashboardCharts";
import DashboardStatsCard from "./DasboardStatsCard";
import dialogTypes from "@/components/Dialogs/dialogTypes";
import dialogSize from "@/lib/calendesk-js-library/components/dialogs/dialogSize";
import planActions from "@/calendesk/mixins/planActions";
import { EventBus } from "@/lib/calendesk-js-library/plugins/eventBus";

export default {
  name: "DashboardStats",
  components: { DashboardStatsCard, DashboardCharts },
  mixins: [planActions],
  computed: {
    ...mapGetters({
      storedStats: "dashboard/getStats",
      notificationUsage: "notification/getUsage",
      webDrafts: "webDraft/drafts",
    }),
    getDomain() {
      if (
        this.webDrafts &&
        this.webDrafts.length > 0 &&
        this.webDrafts[0].domain
      ) {
        return `https://${this.webDrafts[0].domain.domain}`;
      }

      return null;
    },
    hasStats() {
      return this.storedStats !== null;
    },
    stats() {
      return {
        ...this.storedStats,
        lastUpdated: `${this.$trans("last_update")} ${
          this.storedStats.last_updated_at
        }`,
      };
    },
  },
  methods: {
    buyNotificationButtonClicked(data) {
      if (this.loggedUserIsAdmin) {
        const isEmail = data.slug === "email-notifications";

        this.openDialog({
          type: dialogTypes.BUY_NOTIFICATION_DIALOG,
          size: dialogSize.FULL_SCREEN,
          data: this.packageObject(data.slug),
          title: isEmail
            ? this.$trans("choose_email_notifications_package")
            : this.$trans("choose_sms_notifications_package"),
        });
      } else {
        EventBus.$emit("REQUIRED_PERMISSION", data);
      }
    },
    packageObject(slug) {
      return this.extraPackagesUnitPriced.find((x) => x.service.slug === slug);
    },
    goToWebsites() {
      this.$router.push({
        name: "tools",
        params: { tab: "websites" },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.stats__v-card {
  border-radius: 8px !important;
}
.stats__v-card--avatar {
  border-radius: 6px !important;
}

.description {
  height: 100px;
}
</style>
